import { cn } from "../lib/utils";
import { useState, useEffect } from "react";
import { Instagram, Facebook, Youtube } from "lucide-react";
import { useParams } from "react-router-dom";
import { text } from "../text/text";
import { Link } from "react-router-dom";

const Footer = () => {
  const [language, setLanguage] = useState("ro");

  let { lang } = useParams();
  if (!lang) lang = "ro";
  let content = text[language];

  useEffect(() => {
    if (!lang) setLanguage("ro");
    else setLanguage(lang);
  }, [lang]);

  const year = new Date().getFullYear();

  return (
    <section id="footer" className="mt-[3rem]">
      <div className="bg-[#60c6c4] grid gap-y-5 h-fit md:grid-cols-[1fr_1fr_1fr_1fr] px-10 py-10">
        <div className="">
          <a className="w-10 mx-8 h-auto text-white font-serif" href="/">
            <img className="w-1/2" src="logo-white.png" alt="logo" />
          </a>
        </div>
        <div className="flex flex-col w-4/5">
          <h2 className="text-white text-4xl mb-4">{content.footer.col1}</h2>
          <div className="flex flex-col gap-y-1">
            <p className="text-muted-foregrund font-[satoshi]"><a className="hover:underline" href="mailto:sales@selik.md">sales@selik.md</a></p>
            <p className="text-muted-foregrund font-[satoshi]">
              <a className="hover:underline" href="tel:+37379023806">+37379023806</a>
            </p>
            <p className="text-muted-foregrund font-[satoshi,'Manrope']">
              {content.footer.address}
            </p>
          </div>
        </div>
        <div className="flex flex-col items-start w-fit">
          <h2 className="text-white text-4xl mb-4">{content.footer.col2}</h2>
          <div className="flex justify-between w-full font-[universo-thin] text-muted-foreground">
            <Link
              to={"/"}
              className={cn(
                language === "ro" ? "opacity-100" : "opacity-50",
                "hover:underline"
              )}
            >
              RO
            </Link>
            <Link
              to={"/ru"}
              className={cn(
                language === "ru" ? "opacity-100" : "opacity-50",
                "hover:underline"
              )}
            >
              RU
            </Link>
            <Link
              to="/en"
              className={cn(
                language === "en" ? "opacity-100" : "opacity-50",
                "hover:underline"
              )}
            >
              EN
            </Link>
          </div>
        </div>
        <div className="flex sm:mx-8 justify-center items-center">
          <div className="w-8 h-8 md:w-12 md:h-12 2xl:w-20 2xl:h-20 border cursor-pointer rounded-full mx-2 flex items-center justify-center">
            <a href="https://www.instagram.com/selik.md">
              <Instagram
                color="white"
                className="w-4 h-4 md:w-6 md:h-6 xl:w-10 2xl:h-10"
              />
            </a>
          </div>
          <div className="w-8 h-8 md:w-12 md:h-12 2xl:w-20 2xl:h-20 border cursor-pointer rounded-full mx-2 flex items-center justify-center">
            <a href="https://www.facebook.com/selik.md?mibextid=LQQJ4d">
              <Facebook
                color="white"
                className="w-4 h-4 md:h-6 md:w-6 xl:w-10 2xl:h-10"
              />
            </a>
          </div>
          <div className="w-8 h-8 md:w-12 md:h-12 2xl:w-20 2xl:h-20 border cursor-pointer rounded-full mx-2 flex items-center justify-center">
            <a href='https://youtube.com/@travel_radu?si=3VP9y9ppRIj-jfJu'>
            <Youtube
              color="white"
              className="w-4 h-4 md:w-6 md:h-6 xl:w-10 2xl:h-10"
            />
            </a>
          </div>
        </div>
      </div>
      <div className="w-fit mx-auto py-4 flex items-center gap-x-2">
        <p className="font-[satoshi,'Manrope'] text-muted-foreground">
          &copy; {year} {content.footer.rights}{" "}
          <span className="text-[#26CAD3]">website selik.md </span>
        </p>
        <p className="font-[satoshi,'Manrope'] text-muted-foreground">{content.footer.author} <a className="underline" href="https://ionnelli.me/">Ionnelli</a></p>
      </div>
    </section>
  );
};

export default Footer;
