import React, {useState, useEffect} from "react";
import { motion } from "framer-motion";
import { useParams } from "react-router-dom";
import { text } from "../text/text";

const Hero = () => {
  const [language, setLanguage] = useState("ro");

  let { lang } = useParams();
  if (!lang) lang = "ro";
  let content = text[language];

  useEffect(() => {
    if (!lang) setLanguage("ro");
    else setLanguage(lang);
    
  }, [lang]);



  return (
    <section id="hero" className="bg-neutral-900 px-3 mr-4 w-full  md:w-[calc(100%-16px)] relative top-[-96px] h-[calc(100vh+80px)] overflow-hidden flex justify-center md:justify-normal z-0">
       <video src="video3.mp4" type='video/mp4' className="h-full rounded-b-[32px] object-cover w-full z-0" autoPlay loop muted playsInline />
      <motion.p
        variants={{
          hidden: {
            opacity: 0.5,
          },
          visible: {
            opacity: 1,
          },
        }}
        transition={{
          duration: 0.8,
          delay: 0.25,
        }}
        initial="hidden"
        animate="visible"
        className="absolute text-center text-5xl md:text-7xl lg:text-7xl xl:text-8xl 2xl:text-9xl font-bold text-white bottom-1/3 w-4/5 flex justify-center"
      >
        {content.hero.firstLine}
        <br /> <br /> 
      </motion.p>
      <motion.p
        className="absolute text-center md:left-[5%]  text-5xl md:text-7xl lg:text-7xl xl:text-8xl 2xl:text-9xl font-thin text-white bottom-[calc(33.33%-48px)] md:bottom-1/3 w-fit flex justify-center"
        variants={{
          hidden: {
            opacity: 0.5,
          },
          visible: {
            opacity: 1,
          },
        }}
        initial="hidden"
        animate="visible"
        transition={{
          duration: 1,
          delay: 0.25,
        }}
      >
        {content.hero.secondLine}
      </motion.p>
    </section>
  );
};

export default Hero;
